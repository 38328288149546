@media (max-width: 1370px) {

  .receiving {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
  }

  .error {
    margin: 10% auto;
    font-size: 40px;
  }

  .mobiledashheader {
    background-color: #1D9DEB;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    letter-spacing: 1.1px;
    line-height: 29px;
  }

  .mobiledashheader > * {
    color: #F9F9F9;
    font-weight: 900;
}

  .mobileheaderlogo {
    font-family: 'Lato', sans-serif;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 50%;
  }

  .mobileheaderlogo > span:first-of-type {
    letter-spacing: 1.97px;
    font-size: 58px;
    line-height: 51px;
  }

  .mobileheaderlogo > span:nth-of-type(2) {
    line-height: 14px;
    font-size: 18px;
    letter-spacing: 0.55px;
  }

  .mobileheaderuserpic {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .mobileheaderuserpic > img {
    height: 75%;
  }

  .mobilehamburger {
    height: 50%;
    width: 10%;
    background-image: url('../images/whitehamburger.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .todayjobs {
    margin: 5% auto;
    width: 95%;
    text-align: center;
  }

  .todayjobs > h1 {
    letter-spacing: 1.38px;
    font-size: 32px;
    line-height: 35px;
    text-align: left;
    font-weight: 900;
  }

  .employeeinfo {
    width: 100%;
    margin-bottom: 5%;
  }

  .employeeinfo > h2 {
    letter-spacing: 1.01px;
    font-size: 30px;
  }

  .employeeinfo > p {
    font-size: 30px;
    font-weight: 500;
  }

  .jobsbox {
    border: 1px solid #979797;
    border-radius: 3px;
  }

  .todayjob {
    background-color: #EFEFEF;
    border-radius: 3px;
    width: 95%;
    min-height: 17vh;
    margin: 2% auto;
    display: flex;
    flex-wrap: wrap;
  }

  .jobnumber {
    background-color: #1D9DEB;
    border-radius: 50px;
    width: 66px;
    height: 60px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    font-size: 24px;
  }

  .jobdetails {
    width: 85%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    text-align: left;
    margin-bottom: 2%;
  }

  .jobdetails > h1:first-of-type {
    margin-top: 2%;
  }

  .jobdetails > * {
    margin: 1%;
    letter-spacing: 1px;
  }

  .jobdetails > h2, .jobdetails > span {
    font-size: 28px;
  }

  .assignedtome {
    background-color: #FD992E;
    border-radius: 16px;
    padding: 1%;
    font-size: 20px;
    text-align: center;
    font-weight: 900;
    right: 5%;  
    position: absolute;
  }

  .receiveorder {
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .productcategory {
    background-color: #FD992E;
    border-radius: 3px;
    font-weight: 900;
    padding: 2.5% 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }

  .productcategory > span {
    font-size: 30px;
    font-weight: 900;
  }

  .productcategory > img {
    width: 8%;
    height: 40%;
  }

  .productcategory.true {
    margin-bottom: 0;
  }

  .expandedcategory {
    margin-top: 0 !important;
    border: 1px solid #FD992E;
  }

  .categorydetails {
    display: flex;
    margin: 1% auto;
  }

  .categorydetails > h1 {
    font-weight: 400;
    margin-left: 5%;
  }

  .backtoorder {
    width: 100%;
    display: flex;
    align-items: center;
    height: 5vh;
    margin: 5% auto 2% auto;
    font-size: 32px;
    font-weight: 900;
  }

  .backtoorder > img {
    margin-right: 3%;
    height: 60%;
  }

  .receieveoverview {
    background-color: #EFEFEF;
    border-radius: 3px;
    width: 100%;
    margin: 2% auto 0 auto;
    display: flex;
    align-items: center;
    padding: 1%;
  }

  .receivesummary {
    border: 1px solid #979797;
    border-radius: 3px;
    width: 100%;
  }

  .receivesummary > * {
    margin: 5%;
  }

  .beginreceiving {
    background-color: #1D9DEB;
    border-radius: 16px;
    margin: 3% auto;
    height: 9vh;
    width: 50%;
    font-size: 2.5vh;
  }

  .orderscanner {
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }

  .reportissue {
    border: 1px solid #DD1B1B;
    color: #DD1B1B;
    border-radius: 16px;
    padding: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% auto 2% auto;
    font-size: 20px;
    font-weight: 900;
    height: 2vh;
    width: 22%;
  }

  .progresstext {
    margin: auto;
    position: relative;
    top: 3.3vh;
  }

  progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-top: 1%;
    margin-bottom: 3%;
    width: 100%;
    height: 2.2vh;
  }

  progress::-webkit-progress-bar {
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #979797;
  }

  progress::-webkit-progress-value {
    border: 1px solid #979797;
    background-color: #6BE174;
    border-radius: 16px; 
  }

  .scanitem {
    width: 100%;
    margin: auto;
  }

  .barcodescan {
    margin-top: 5%;
    width: 100%;
    border: 1px solid #979797;
    border-radius: 3px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    color: #FD992E;
  }

  .barcodescan > * {
    margin: 10% 20%;
    text-align: center;
    letter-spacing: 1.01px;
  }

  .scannerdetails {
    background-color: #EFEFEF;
    margin: 5% auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: left;
  }

  .scannerdetails > h1 {
    width: 25%;
    margin-left: 5%;
  }

  .scannerdetails > span {
    margin-left: 5%;
    font-size: 4vw;
    height: 33px;
    width: 60%;
    border-bottom: 1px dashed #979797;
  }

  .beginreceiving.false {
    filter: opacity(40%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
  }

  .receivingordercomplete {
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .congrats {
    border: 1px solid #979797;
    width: 100%;
    margin-top: 5%;
    display: flex;
    flex-wrap: wrap;
    padding: 4%;
  }

  .congrats > div {
    width: 70%;
    margin-left: auto;
    letter-spacing: 2px;
  }

  .hugeheader {
    font-size: 48px;
    margin-top: 1%;
    font-weight: 900;
  }

  .orderupdate {
    background-color: #EFEFEF;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
    font-size: 28px;
    font-weight: 900;
    letter-spacing: 1.01px;
    padding: 3%;
  }

  .orderupdate > ul > li {
    margin: 5% auto;
  }

  .putawaylater {
    border: 1px solid #1D9DEB;
    border-radius: 16px;
    background-color: #fff;
    width: 60%;
    height: 5vh;
    font-size: 2.5vh;
    margin: 3% auto;
  }

  .locations {
    display: flex;
    flex-wrap: wrap;
  }

  .locations > div:first-of-type {
    width: 30%;
  }

  .scanlocation {
    background-color: #EFEFEF;
    margin: 2% auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: left;
  }

  .scanlocation > div:nth-of-type(1) {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
  }

  .scanlocation > div:nth-of-type(1) > div {
    width: 95%;
    display: flex;
    margin-left: 5%;
  }

  .scanlocation > div:nth-of-type(2) {
    color: #FD992E;
    text-align: center;
  }

  .itemputaway {
    background-color: #EFEFEF;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 2% auto;
    justify-content: space-between;
  }

  .itemputaway > h1 {
    color: #1D9DEB;
    width: 25%;
    display: flex;
    align-items: center;
  }

  .itemputaway > div:first-of-type {
    text-align: left;
    margin-left: 5%;
    width: 66%;
  }

  canvas {
    height: 0;
  }

}