p {
    margin: 0;
}

.shipping, .payment {
  width: 50%;
}

.review {
  width: 60%;
}

.cartproducts > h2 {
  text-align: center;
  letter-spacing: 1.01px;
  /* width: 49%; */
}

.shipping, .payment, .delivery, .review {
    color: #000;
    margin-bottom: 5%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.delivery.done {
  width: 50%;
}

.form {
    display: flex;
    flex-flow: row wrap;
    border: 0px 1px ;
    color: #0c0b0b;
  }
  
  .form > input, .form > select {
    color: #0C0C0C;
    border: none;
    margin: 2% 5%;
    padding: 2% 0;
    border-bottom: 1px solid black;
    font-size: 16px;
  }
  
  .form > input:focus {
    outline: none;
  }
  
  .form > select {
    border: none;
    background-color: #fff;
    margin: 3% auto;
  }
  
  select:focus {
    outline: none;
  }

  .formtitle {
    width: 100%;
    font-size: 30px;
    font-weight: 900;
  }

  .formtitle.upcoming {
    color: #808080;
  }

.formtitle.active, .formtitle.edit {
    color: #1D9DEB;
  }

.checkoutbutton {
    color: black;
    font-size: 1.2vw;
    height: 7vh;
    width: 30%;
    border-radius: 16px;
    margin: 5% auto;
    margin-right: 1%;
}

.editbutton {
    text-align: center;
    margin: auto;
    text-decoration: underline;
    color: #1D9DEB;
    letter-spacing: 1.01px;
    line-height: 26px;
    font-size: 22px;
    font-weight: 600;
  }

.datepicker {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  margin-top: 7%;
}

.datepicker:nth-of-type(1) {
  margin-top: 1%;
}

.calendarsection {
  width: 45%;
}

.calendarsection > p {
  margin-bottom: 2%;
}

.react-calendar {
  margin-top: 5%;
  width: 75% !important;
  height: 80%;
  box-shadow: 1px 2px 4px 0 rgba(0,0,0,1);
  border-radius: 6px;
}
    
.timeselect {
  width: 30%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-right: 15%;
}

.dateandtime {
  display: flex;
  flex-flow: column;
  width: 50%;
  justify-content: space-between;
}

.datestimes {
  display: flex;
  flex-flow: row wrap;
  margin: 2% auto;
}

.datestimes > p {
  margin: 0 5%;
}

.timeselect > div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 40%;
  margin-top: 5%;
  margin-right: 5%;
}

.checkbox {
	-webkit-appearance: none;
	background-color: #fafafa;
  border: 1px solid #1D9DEB !important;
	padding: 9px;
}

.checkbox:focus {
  border: none;
}

.checkbox:checked {
  background-color: #1D9DEB;
  outline: 1px solid white;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__tile--active {
  border-radius: 50px;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  font-size: 2vw;
  width: 33%;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  font-size: 2vw;
  width: 30%;
}

.react-calendar__navigation__label {
  width: 35%;
  font-size: 1.5vw;
}

.react-calendar__tile--active {
  background-color: #1D9DEB !important;
}