body {
    background-color: #fff !important;
}

.dashboard {
    display: flex;
    flex-flow: row wrap;
  }
  
.dashheader {
    z-index: 1;
    background-color: #1D9DEB;
    height: 15vh;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    letter-spacing: 1.1px;
    line-height: 29px;
    cursor: pointer;
}

.headerlogo {
    font-family: 'Lato', sans-serif;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.headerlogo > span:nth-of-type(1) {
    letter-spacing: 1.97px;
    font-size: 43px;
}

.headerlogo > span:nth-of-type(2) {
    font-size: 12px;
}

.dashheader > * {
    color: #F9F9F9;
    font-weight: 900;
    font-size: 24px;
}

.headeruserpic {
    margin-top: 5%;
    cursor: pointer;
    text-align: right;
    display: flex;
    justify-content: space-around;
}

.headeruserpic > img {
    width: 2.5vw;
    border-radius: 50px;
}

.headerdropdown {
    position: absolute;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    right: 5vw;
    top: 15vh;
    width: 16%;
    background-color: gray;
    box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.5);
    transition: all .5s;
}

.headerdropdown > * {
    margin-top: 5%;
    font-size: 2vh;
}

.sidenav {
    background-color: #1D2124;
    color: #FFFFFF;
    width: 100px;
    position: fixed;
    padding-top: 15vh;
    display: flex;
    flex-flow: column;
    height: 100vh;
}

.sidenavitem {
    cursor: pointer;
    height: 20%;
    font-size: 12px;
    font-weight: 900;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.sidenavitem > img {
    height: 33%;
    width: 50%;
    margin-bottom: 10%;
}

.sidenavitem.active {
    background-color: #FFFFFF;
    color: #000000;
    border: 2px solid #1D2124;
}

.portalbutton {
    margin: 0 auto;
    font-weight: bold;
    letter-spacing: 1.1px;
    line-height: 29px;
    cursor: pointer;
}

.portalbg {
    height: 33vh;
    width: 25vw;
    border-radius: 16px;
    box-shadow: 1px 2px 4px 0 rgba(0,0,0,1);
    background-size: 66%;
    background-repeat: no-repeat;
    background-position: center;
}

.portalbutton.first > div {
    background-color: #F6FBFE;
}

.portalbutton.second > div {
    background-color: #A7DAA7;
}

.portalbutton.third > div {
    background-color: #9BCCED;
}

@media (max-width: 1370px) {



}