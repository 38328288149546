/* ! Flickity v2.1.2
https://flickity.metafizzy.co
---------------------------------------------- */

.carousel-cell {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: #38A1F2;
  display: flex;
}

.carousel-cell > div {
  width: 50%;
  color: #fff;
  text-align: left;
  margin: auto auto auto 8%;
  font-family: helvetica;
  padding-right: 5%;
}

.carousel-cell > div > h1 {
  font-size: 50px;
  margin-top: 0;
}

.carousel-cell > div > h3 {
  font-size: 20px;
  font-weight: 500;
}

.carousel-cell > div > button {
  color: #fff;
  border: 2px solid #FFFFFF;
  border-radius: 23px;
  height: 50px;
  font-size: 18px;
  width: 200px;
  margin: 3% auto;
}

.carousel-cell > img {
  /* width: 30%; */
  width: 350px;
  height: 300px;
  text-align: center;
  margin: auto 8% auto auto;
}

/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;
  line-height: 200px;
  font-size: 80px;
  color: white;
}

.flickity-enabled {
  position: relative;
}
  
.flickity-enabled:focus {
  outline: none;
}
  
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 75vh !important;
}
  
.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}
  
  /* draggable */
  
  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    /* tap-highlight-color: transparent; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
  
  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  
  /* ---- flickity-button ---- */
  
  .flickity-button {
    position: absolute;
    background: hsla(0, 0%, 100%, 0.75);
    border: none;
    color: #333;
  }
  
  .flickity-button:hover {
    background: white;
    cursor: pointer;
  }
  
  .flickity-button:focus {
    outline: none;
    box-shadow: 0 0 0 5px #19f;
  }
  
  .flickity-button:active {
    opacity: 0.6;
  }
  
  .flickity-button:disabled {
    opacity: 0.3;
    cursor: auto;
    /* prevent disabled button from capturing pointer up event. #716 */
    pointer-events: none;
  }
  
  .flickity-button-icon {
    fill: #333;
  }
  
  /* ---- previous/next buttons ---- */
  
  .flickity-prev-next-button {
    top: 50%;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%);
  }
  
  .flickity-prev-next-button.previous {
    left: 3px;
  }
  .flickity-prev-next-button.next {
    right: 3px;
  }
  /* right to left */
  .flickity-rtl .flickity-prev-next-button.previous {
    left: auto;
    right: 10px;
  }
  .flickity-rtl .flickity-prev-next-button.next {
    right: auto;
    left: 10px;
  }
  
  .flickity-prev-next-button .flickity-button-icon {
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
  }
  
  /* ---- page dots ---- */
  
  .flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: 2%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
  }
  
  .flickity-rtl .flickity-page-dots {
    direction: rtl;
  }
  
  .flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background-color: #D8D8D8;
    border-radius: 50%;
    opacity: 0.25;
    cursor: pointer;
  }
  
  .flickity-page-dots .dot.is-selected {
    background-color: #fff;
    opacity: 1;
  }
  
@media (max-width: 900px) {

  .carousel-cell {
    flex-direction: column;
  }

  .carousel-cell > div {
    width: 66%;
    margin: auto;
    padding: 0;
  }

  .carousel-cell > img {
    display: none;
  }

}