.cop select {
    border: 1px solid #979797;
    background-color: #D8D8D8;
    border-radius: 3px;
}

.bigheader {
    font-size: 52px;
    letter-spacing: 2.38px;
    line-height: 61px;
}

.smallheader {
    font-size: 30px;
    letter-spacing: 1.38px;
    line-height: 35px;
}

.cop {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 95%;
    margin-left: 100px;
    margin-bottom: 2%;
    text-align: center;
}

#copportalone {
    background-image: url('../images/clipboardportalbutton.png');
    background-size: 30%;
}

#copportaltwo {
    background-image: url('../images/trackorderportalbutton.png');
    background-size: 40%;
}

#copportalthree {
    background-image: url('../images/orderhistoryportalbutton.png');
    background-size: 40%;
}

.createorder {
    width: 100%;
    margin: 1% 3%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.viewcart {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.viewcart > img {
    height: 50%;
}

.viewcart > p {
    position: relative;
    right: 20%;
    bottom: 4%;
    font-size: 24px;
    color : #FD992E;
    font-weight : 900;
}

.search {
    margin-top: 5%;
    width: 100%;
    display: flex;
    border: 1px solid #979797;
}

.search > img {
    height: 5vh;
    margin: auto;
    margin-left: 2%;
}

.searchbar {
    border: none;
    width: 90%;
    height: 5vh;
    padding: 1%;
    font-size: 18px;
    margin-left: 2%;
}

.searchbar:focus {
    border: none;
}

.productlist {
    width: 80%;
    text-align: left;
    margin-top: 3%;
}

.productlist > h4 {
    font-size: 22px;
}

.categorylist {
    width: 15%;
    border-right: 1px solid black;
    text-align: left;
    padding-right: 3%;
    margin-top: 3%;
    display: flex;
    flex-flow: column;
}

.categorylist > h4 {
    font-size: 22px;
}

.categorylist > span {
    margin-top: 10%;
    font-size: 16px;
}

.productlisting {
    width: 100%;
    height: 10vh;
    margin: 5% auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
}

.productlisting > img {
    width: 5%;
    height: 75%;
    margin-right: 4%;
}

.productlisting > div {
    display: flex;
    flex-direction: column;
}

.pricepercase {
    margin-left: 2%;
    width: 20%;
    text-align: left;
}

.nameandsku {
    width: 25%;
}

.nameandsku > span {
    margin-bottom: 5%;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.73px;
}

.quantityselector {
    width: 20%;
    align-items: center;
    margin-right: 2%;
}

.quantityselector > select {
    margin-top: 2%;
    font-size: 2vh;
}

.addtocart {
    border-radius: 16px;
    color: #000000;
    height: 66%;
    font-size: 16px;
    width: 30%;
}

.addtocart.true {
    background-color: #6BE174;
}

.cart {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.cartproducts {
    width: 55%;
    margin: 2%
}

.cartproducts > h4 {
    font-size: 22px;
    font-weight: 900;
    text-align: right;
    margin-right: 20%;
}

.remove {
    display: flex;
    flex-direction: column;
    color: red;
    cursor: pointer;
    text-align: center;
    font-weight: 900;
}

.backtoorder {
    margin-top: 1%;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    letter-spacing: 1.01px;
    line-height: 26px;
    width: 100%;
}

.backtoorder > img {
    height: 50%;
    margin-right: 2%;
}

.backtoorder > span {
    font-size: 22px;
    letter-spacing: 1.01px;
    line-height: 26px;
    font-weight: 600;
}

.subtotal {
    width: 30%;
    text-align: left;
    margin: 2%;
}

.subtotal > h4 {
    font-size: 22px;
    font-weight: 900;
}

.checkout {
    width: 95%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.checkoutinfo {
    width: 100%;
    text-align: left;
}

.checkoutsummary {
    height: 33vh;
    width: 35%;
    position: absolute;
    top: 35%;
    right: 0;
    display: flex;
    flex-flow: row wrap;
    text-align: left;
    justify-content: space-around;
}

.checkoutsummary > h1 {
    font-size: 30px;
    width: 100%;
    font-weight: 900;
}

.checkoutsummary > * {
    width: 50%;
    margin: 0;
}

.orderplaced {
    border: 1px solid #979797;
    display: flex;
    align-items: center;
    width: 95%;
    margin: 3% auto 0 auto;
    text-align: left;
}

.orderplaced > button {
    height: 25%;
    width: 25%;
    margin: auto;
}

.orderplaced > img {
    height: 50%;
    margin: 0 3%;
}

.orderheader{
    letter-spacing: 1px;
    line-height: 35px;
    font-size: 30px;
    font-weight: 900;
}

.orderplaced > div > * {
    margin-bottom: 5%;
}

.trackorders {
    width: 95%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 5%;
}

.ordertitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ordertitle > h2 {
    letter-spacing: 1.2px
}

.orderconfirmationbox {
    border: 1px solid #979797;
    width: 100%;
    min-height: 27vh;
    text-align: left;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}

.orderconfirmationbox.false > * {
    color: #FD992E;
    width: 90%;
    margin: auto;
}

.orderconfirmationbox.true > div {
    width: 66%;
    margin-left: 3%;
}

.orderconfirmationbox.true > div > * {
    color: #6BE174;
    margin: 1% auto;
}

.orderconfirmationbox.true > div > h3 {
    margin-top: 2%;
}

.orderconfirmationbox.true > div > p {
    margin: 2% 0;
}

.orderconfirmationbox > button {
    width: 24%;
    height: 25%;
    margin: auto 2%;
}

.ordercomplete {
    display: flex;
    align-items: center;
    width: 100% !important;
}

.ordercomplete > img {
    height: 75%;
    margin: auto 2% auto 0;
}

.ordercomplete > div {
    width: 75%;
    height: 75%;
    display: flex;
    flex-flow: row wrap;
}

.ordercomplete > div > h2 {
    margin: 0;
}

.ordercomplete > div > p {
    color: #000;
    width: 100%;
    font-weight: 600;
}

.orderinformation {
    width: 50%;
    text-align: left;
}

.orderinformation > div > p {
    font-size: 18px;
}

.orderdeliverydate > div {
    display: flex;
}

.orderdeliverydate > div > p {
    font-size: 18px;
    margin: 2%;
}

.leavemessage {
    width: 47%;
    text-align: left;
    display: flex;
    flex-flow: column;
}

.leavemessage > textarea {
    width: 100%;
    height: 13vh;
    font-size: 1.5vw;
    padding: 3%;
    border: 1px solid #979797;
    border-radius: 3px;
}

.leavemessage > button {
    width: 20%;
    margin-top: 4%;
    height: 6vh;
    align-self: flex-end;
}

.leavemessage > h3 {
    color: #6BE174;
    margin: 20% auto;
}

.orderdeliverydate {
    display: flex;
    flex-flow: row wrap;
}

.orderdeliverydate > h2 {
    width: 100%;
}

.orderdeliverydate > p {
    font-size: 2vw !important;
    margin-right: 5%;
}

.orderinformation > div {
    color: #000;
}

.orderinfosummary {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}

.orderinfosummary > p, .ordersummary > b {
    width: 49%;
    margin-top: 2%;
}

.trackordersbox {
    width: 100%;
    min-height: 20vh;
    display: flex;
    flex-flow: row wrap;
    border: 1px solid #979797;
    border-radius: 3px;
}

.customerorder {
    width: 90%;
    display: flex;
    flex-flow: column;
    border-radius: 3px 3px 0 3px;
    margin: 2% auto;
    height: 20vh;
}

.customerorderbox-top {
    background-color: #1D9DEB;
    border-radius: 3px 3px 0 0;
    height: 33%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customerorderbox-top > * {
    margin: auto 2%;
    font-size: 22px;
    font-weight: 900;
}

.customerorderbox-bottom {
    background-color: #EFEFEF;
    border: 1px solid #979797;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 67%;
    text-align: left;
}

.customerorderbox-bottom > div {
    margin: 2%;
}

.customerorderbox-bottom > div > * {
    color: #000;
    margin: 3% 0;
    font-size: 18px;
}

.customerorderbox-bottom > button {
    width: 25%;
    height: 50%;
    margin-right: 5%;
}

@media (max-width: 900px){

    .cop {
        flex-flow: column;
    }

    .portalbutton {
        margin: 2% auto;
        width: 50%;
    }

    .portalbg {
        width: 100%;
    }

}