body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  outline: none !important;
  background-color: rgb(29, 157, 235);
  font-weight: 500;
  cursor: pointer;
}

span:hover {
  cursor: pointer;
}

input {
  outline: none;
}

.logform > input {
  width: 45vw;
  padding: 10px;
  margin: 2% auto;
  max-width: 500px;
  width: 66vw;
  font-size: 18px;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
}

.formbutton {
  width: 25vw;
  height: 5vh;
  border-radius: 10px !important;
  color: white;
}

.header {
  background-color: #fff !important;
  font-family: Helvetica;
  color: #4A4A4A;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #9B9B9B;
  border-top: 1px solid #9B9B9B;
  position: sticky;
  top: 0;
  z-index: 2;
  transition: height .5s linear !important;
}

.header.small {
  height: 80px;
}

.header.big {
  height: 100px;
}

.other > * {
  margin: 0 20px;
}

.other > *, .logo {
  font-size: 20px;
}

.logo {
  margin-left: 3%;
}

.logo > * {
  color: #379EEB;
  font-size: 48px;
  font-weight: 500;
}

.navactive {
  color: rgb(29, 157, 235);
}

.regbutton {
  background-color: #FFC753;
  border-radius: 50px;
  margin-right: 0;
  padding: 7px 25px;
}

.regbutton.active {
  background-color: #fff;
  color: rgb(29, 157, 235);
  box-shadow: 0 0 0 1.5px rgb(251, 190, 14);

}

.main, .login, .register, .services, .about, .regconfirm {
  display: flex;
  flex-flow: column;
  font-family: helvetica;
}

.title {
  margin: 5% auto auto auto;
  font-size: 3vw;
}

.maininfo {
  text-align: left;
  color: #4A4A4A;
}

.smallpic {
  width: 25vw !important;
}

.info {
  margin: 10% 8%;
  font-weight: 900;
}

.info > p {
  font-size: 24px;
}

.mainsection {
  display: flex;
  flex-wrap: wrap;
  margin: 0 8%;
}

.mainsection > div > b {
  font-size: 16px;
  margin: 3% 0;
}

.mainsection > div > p {
  font-size: 16px;
}

.mainsection > div:last-of-type {
  margin-right: 0;
}

.emojis {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  margin-left: 0;
}

.emojis > div, .emojitext {
  width: 22%;
  margin: 0 auto;
}

.emojitext {
  font-size: 15px;
}

.emojitext > p {
  margin-top: 5%;
}

.emojis > div > img {
  width: 18%;
}

.plantemoji {
  width: 22% !important;
}

.staremoji {
  width: 28% !important;
}

.emojiinfo {
  display: flex;
}

.youmeaneverything {
  margin: 8%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.youmeaneverything > img {
  width: 25%;
}

.youmeaneverything > div {
  margin-left: 5%;
  width: 60%;
}

.youmeaneverything > div > p {
  margin-top: 2%;
  font-size: 20px;
}

.youmeaneverything > div > b {
  font-size: 24px;
}

.mainblue {
  background-color: #38A1F2;
  display: flex;
  color: #fff;
  margin: 5% 0;
}

.mainblue > div {
  width: 65%;
  margin: 5% 0 5% 8%;
}

.mainblue > div > h1 {
  font-size: 28px;
  margin: 0;
}

.mainblue > div > p:first-of-type {
  margin-top: 1%;
}

.mainblue > div > p {
  font-size: 20px;
  margin-top: 2%;
  width: 75%;
}

.mainblue > button {
  color: #fff;
  border: 2px solid #FFFFFF;
  border-radius: 23px;
  height: 50px;
  font-size: 18px;
  width: 200px;
  margin: auto 10%;
}

.joinsocialmedia {
  margin: 6% 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.smallsocial {
  height: 100px !important;
}

.joinsocialmedia > img {
  min-width: 5%;
  height: 75px;
  margin-top: 5%;
  cursor: pointer;
}

.joinsocialmedia > img:first-of-type {
  margin-left: 0;
}

.joinsocialmedia > div {
  width: 100%;
}

.joinsocialmedia > div > p {
  font-size: 18px;
  width: 95%;
}

.getademo {
  color: #4A4A4A !important;
  background-color: #FFC753 !important;
  border: 1px solid #FFC753 !important;
  border-radius: 28.5px !important;
}

.services, .about {
  color: #4A4A4A;
}

.servicesdiv {
  width: 90%;
  margin: 5% 8%;
}

.servicesdiv > h1 {
  font-size: 45px;
}

.servicesdiv > p {
  font-size: 25px;
}

.servicesblue {
  margin: 5% 0;
  background-color: #38A1F2;
  display: flex;
  align-content: center;
  justify-content: center;
}

.servicesblue > img {
  margin: 5% auto;
  width: 50%;
}

.servicesinfo {
  display: flex;
  align-items: center;
  margin: 5% 8%;
}

.servicesinfo > img {
  width: 200px;
  height: 200px;
  margin: 0 6%;
}

.servicesinfo > div {
  width: 70%;
}

.servicesinfo > div > p {
  margin-top: 2%;
  font-size: 20px;
}

.servicesinfo > div > b {
  font-size: 30px;
}

.aboutsection {
  display: flex;
  align-items: center;
}

.aboutsection {
  margin: 5% 0;
}

.aboutsection.right > img {
  margin: 0 8% 0 4%;
}

.aboutsection.right > div {
  margin: 0 4% 0 8%;
}

.aboutsection.left > img {
  margin: 0 4% 0 8%;
}

.aboutsection.left > div {
  margin: 0 8% 0 4%;
}

.aboutsection > div {
  width: 70%;
}

.aboutsection > img {
  width: 30%;
  min-height: 150px;
}

.aboutsection > div > h1 {
  color: #38A1F2;
  font-weight: 400;
  font-size: 45px;
  margin: 0;
}

.aboutsection > div > p {
  font-size: 18px;
  margin: 3% auto;
  line-height: 24px;
}

.about > h1 {
  color: #38A1F2;
  font-weight: 400;
  font-size: 45px;
  margin: 0;
  margin-top: 7%;
  margin-left: 8%;
}

.leadership {
  margin: 5% 8%;
  margin-bottom: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.portrait {
  display: flex;
  flex-direction: column;
  border: 1px solid #979797;
  border-radius: 22px;
  width: 19%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}

.portrait > img:first-of-type {
  width: 50%;
  margin: 10% auto;
}

.portrait > img:last-of-type {
  width: 25%;
  margin: 10% auto;
  cursor: pointer;
}

.portrait > p {
  margin: 1% auto;
  text-align: center;
  font-size: 20px;
}

.portrait > p:first-of-type {
  font-weight: 900;
}

.login {
  background-color: #F6F8FA;
  color: #4A4A4A;
  width: 100%;
  height: calc(100vh - 110px);
  overflow: hidden;
}

.login > div:first-of-type {
  width: 40%;
  margin: auto;
  text-align: left;
}

.login > div > h1 {
  font-size: 45px;
  text-align: center;
  margin-top: 0;
}

.uppergear {
  width: 25%;
  position: absolute;
}

.lowergear {
  width: 23%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.login > div > label, .login > div > div > label {
  font-size: 20px;
  font-weight: bold;
}

.login > div > input {
  width: 100%;
  margin: 2% auto;
  border: 1px solid #979797;
  border-radius: 4px;
  background-color: #FFFFFF;
  color: #4A4A4A;
  box-shadow: 0 7px 16px 0 rgba(0,0,0,0.5), 0 2px 4px 0 rgba(0,0,0,0.5);
  height: 33px;
  font-size: 20px;
  padding: 10px;
}

.login > div > input:first-of-type {
  margin-bottom: 5%;
}

.buttonholder {
  text-align: center;
  height: 12%;
}

.loginbutton {
  border: 1px solid #38A1F2;
  border-radius: 29px;
  background-color: #38A1F2;
  box-shadow: 0 7px 24px 0 rgba(0,0,0,0.5);
  margin-top: 5%;
  width: 175px;
  height: 100%;
  color: #fff;
  font-size: 18px;
}

.forgotdiv {
  display: flex;
  justify-content: space-between;
}

.forgotpw {
  text-decoration: underline;
  color: #7F7C80;
  cursor: pointer;
  font-size: 18px;
}

.footer {
  border-top: 1px solid #38A1F2;
  padding-top: 2%;
  margin: 5% auto 2% auto;
  height: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer > * {
  cursor: pointer;
  margin: 0 5%;
}

.invalidCred {
  border: 1px solid red;
}

.logform {
  display: flex;
  justify-content: center;
  flex-flow: column;
  margin: 5% auto;
  text-align: left;
  max-width: 500px;
  width: 66vw;
}

.formbutton {
  margin: 5% 0;
}

button:active {
  animation: click .5s linear;
}

.flickity-button:active {
  animation: none !important;
}

button:focus {
  outline: none;
}

.radio {
  display: flex;
  flex-flow: row;
  justify-content: center;
  text-align: center;
  margin: 5% 0 7% auto;
}

.radiobutton {
  height: 10vh;
  width: 100px;
  margin: 2% 4%;
}
  
.radiobutton > input {
  margin-top: 10%;
  transform: scale(1.5);
}

.reginputs {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 10% auto;
  max-width: 500px;
  width: 66vw;
}

.short {
  width: 41% !important;
}

.reginputs > input {
  padding: 10px;
  margin: 2%;
  border-radius: 5px;
  width: 100%;
}

.shipper {
  background-image: url('./images/shipper.png');
}

.carrier {
  background-image: url('./images/carrier.png');
}

.warehouse {
  background-image: url('./images/warehouse.png');
}

.customer {
  background-image: url('./images/customer.png');
}

.shipper, .carrier, .warehouse, .customer {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 8vw;
}

.mobileheader {
  display: none;
}

.dropmenu.false {
  width: 0;
}

.dropmenu.false > * {
  display: none;
}


/* ///////////////////////////// */
/*                               */
/*             Desktop           */
/*                               */
/* ///////////////////////////// */


@media (max-width: 1030px) {

  /* .header.small {
    height: 8vw;
  }
  
  .header.big {
    height: 10vw;
  } */

}

/* /////////////////////////////// */
/*                                 */
/*          Mobile                 */
/*                                 */
/* /////////////////////////////// */


@media (max-width: 880px) {

  h1 {
    font-size: 24px !important;
  }

  h3 {
    font-size: 18px !important;
  }

  li {
    font-size: 3.3vw;
  }

  label {
    font-size: 3.5vw;
    font-weight: 500 !important;
  }

  span {
    font-size: 3vw;
  }

  .leadership {
    flex-wrap: wrap;
  }

  .portrait {
    width: 75%;
    margin: 5% auto;
  }

  .portrait > p {
    font-size: 20px;
  }

  .portrait > img:last-of-type {
    margin: 5% auto;
  }

  .mainsection {
    height: 80vh;
  }

  .mainblue {
    flex-wrap: wrap;
  }

  .mainblue > div {
    width: 100%;
    margin: 5% 8%;
  }

  .mainblue > button {
    width: 50%;
    margin: 7% 8%;
  }

  .mainblue > div > h1 {
    font-size: 20px !important;
  }

  .mainblue > div > p {
    font-size: 14px;
    width: 100%;
  }

  .servicesblue > img {
    width: 200px;
    height: 160px;
    margin: 10% auto;
  }

  .emojis {
    flex-direction: column !important;
    width: 15% !important;
    margin-right: 5%;
    height: 100%;
    justify-content: space-around;
  }

  .emojis > div {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25%;
  }

  .emojis > div > img {
    width: 75%;
  }

  .plantemoji {
    width: 100% !important;
  }

  .staremoji {
    width: 110% !important;
  }

  .emojiinfo {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .emojitext {
    width: 100%;
    margin: 5% 0;
    margin-top: 0;
  }

  .youmeaneverything > div > p {
    font-size: 14px;
  }

  .youmeaneverything > div > b {
    font-size: 20px;
  }

  .info > p {
    font-size: 20px;
  }

  .servicesinfo > div > b {
    font-size: 20px;
  }

  .servicesinfo > div > p {
    font-size: 14px;
  }

  .servicesinfo > img {
    height: 100px !important;
    width: 100px !important;
  }

  .aboutsection > div > p {
    font-size: 12px;
    line-height: 16px;
  }

  .aboutsection > img {
    min-height: 100px;
  }

  .login {
    height: calc(100vh - 95px);
  }

  .login > div {
    width: 66% !important;
    height: 50%;
  }
  
  .login > h1 {
    font-size: 45px;
  }

  .buttonholder {
    height: 16%;
  }

  .loginbutton {
    width: 200px;
  }
  
  .uppergear {
    width: 33%;
  }
  
  .lowergear {
    width: 33%;
  }

  .mainsection > div > img {
    width: 30%;
  }

  .joinsocialmedia > div > h1 {
    font-size: 20px !important;
  }

  .joinsocialmedia > div > p {
    font-size: 14px !important;
  }

  .joinsocialmedia > img  {
    height: 5vh;
  }

  .joinsocialmedia > .smallsocial {
    height: 7.5vh !important;
  }

  .logo > * {
    font-size: 40px;
  }

  .header {
    display: none;
  }

  .mobileheader {
    background-color: #fff !important;
    height: 75px;
    border-bottom: 1px solid #eceef1;
    padding: 10px 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .dropbutton {
    width: 8vw;
    height: 8vw;
    background-image: url('./images/hamburger.png');
    background-size: contain;
    transition: background-image 0.25s ease-in-out;
  }

  .dropbutton.true {
    background-image: url('./images/closemenu.png');
  }

  .dropmenu {
    width: 30vw;
    height: 100vh;
    position: fixed;
    z-index: 2;
    right: 0;
    background-color: #fff;
    transition: all .25s;
  }

  .dropmenu > div {
    width: 100%;
    margin-top: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dropnav {
    margin: 10% auto;
    font-weight: 900;
    letter-spacing: 1px;
  }

  .dropnav.blue > * {
    color: rgb(29, 157, 235);
  }

}

/* ///////////////////// */
/*                       */
/*      Animations       */
/*                       */
/* ///////////////////// */

@keyframes click {
  0%   {transform: scale(1);}
  50%  {transform: scale(0.8);}
  100% {transform: scale(1);}
}