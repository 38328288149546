.incomingordernotification {
    position: relative;
    border-radius: 50%;
    top: -5%;
    left: -5%;
    background-color: #DD1B1B;
    height: 33px;
    width: 33px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: inherit;
}

.incomingordernotification.small {
    top: 10%;
    left: -15%;
}

.incomingordernotification.incoming {
    height: 4.5vh;
    margin-left: 10%;
}

#wmsportalone {
    background-image: url('../images/managerportalbutton.png');
    background-size: contain;
    margin: 0 auto;
}

#wmsportaltwo {
    background-image: url('../images/wmsportalbutton.png');
    background-size: contain;
    margin: 0 auto;
}

#wmsportalthree {
    background-image: url('../images/baportalbutton.png');
    background-size: contain;
    margin: 0 auto;
}

.manager {
    width: 95%;
    display: flex;
    flex-flow: row wrap;
}

.ordersbox {
    width: 100%;
    outline: 1px solid #1D9DEB;
}

.ordersboxtabs {
    display: flex;
}

.ordersboxtab {
    color: #000;
    width: 25%;
    height: 8vh;
    border-right: 1px solid #1D9DEB;
    border-bottom: 1px solid #1D9DEB;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    cursor: pointer;
    font-size: 18px;
}

.ordersboxtab > p {
    font-size: 24px;
    margin-left: 5%;
}

.ordersboxtab.active {
    background-color: #1D9DEB;
    color: #fff;
}

.timeslots {
    height: 100%;
}

.orderdetails {
    width: 95%;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 2%;
}

.orderdetails > h2, .orderdetails > p, .orderdetails > h4 {
    width: 100%;
    text-align: left;
    color: #000;
}

.orderdetails > p {
    font-size: 2vw;
}

.orderitemstitles {
    font-weight: 900;
    text-align: center;
    width: 100%;
    display: flex;
    margin-bottom: 1%;
}

.orderitemstitles > div {
    flex: 1;
    font-size: 1.5vw;
}

.orderitems {
    width: 100%;
    border: 1px solid #979797;
    display: flex;
    flex-flow: row wrap;
}

.orderitem {
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    margin: 1% auto;
}

.orderitem > div {
    flex: 1;
    font-size: 18px;
}

.imgandname {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: left;
}

.imgandname > img {
    width: 15%;
    height: 50%;
    margin: 0 12%;
}

.orderpricinginfo {
    text-align: left;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    margin: 1% 0 1% 20%;
    font-size: 1.5vw;
}

.orderpricinginfo > div {
    font-weight: 500;
    width: 50%;
    margin-top: 2%;
}

.orderpricinginfo > p {
    width: 50%;
    margin-top: 2%;
}

.orderpricinginfo > span {
    font-weight: 900;
    width: 50%;
    margin-top: 2%;
}

.orderpricinginfo > span:nth-of-type(3) {
    width: 100%;
    text-align: center;
}

.ordershippingdates {
    text-align: left;
}

.transportbutton {
    border-radius: 16px;
    background-color: #FD992E;
    height: 6vh;
    width: 25%;
    font-size: 16px;
}

.today {
    width: 100%;
}

.todayorder {
    display: flex;
    align-items: center;
    width: 100%;
    height: 25vh;
}

.timeslot {
    width: 15%;
}

.todayorderbox {    
    display: flex;
    flex-wrap: wrap;
    margin: 0 2%;
    width: 100%;
    height: 75%;
    justify-content: space-evenly;
    border: 1px solid #979797;
    border-radius: 3px 3px 0 3px;    
    background-color: #EFEFEF;
    text-align: left;
    cursor: pointer;
}

.todayorderbox-top {
    text-align: left;
    height: 30%;
    width: 100%;
    border-radius: 3px 3px 0 0;    
    background-color: #1D9DEB;
    display: flex;
    align-items: center;
}

.todayorderbox-top > * {
    margin-left: 2%;
    width: 50%;
}

.todayorderbox-bottom {
    width: 100%;
    height: 70%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    border-radius: 3px 3px 0 3px;    
}

.todayorderbox-bottom > div > h4 {
    margin: 5% auto;
}

.incomingorders {
    width: 100%;
}

.incomingorder {
    border: 1px solid #979797;
    border-radius: 3px 3px 0 3px;
    background-color: #EFEFEF;
    width: 95%;
    height: 20vh;
    margin: 2% auto;
    display: flex;
    flex-flow: row wrap;
}

.incomingorderbox-top {
    text-align: left;
    height: 30%;
    width: 100%;
    border-radius: 3px 3px 0 0;    
    background-color: #1D9DEB;
    display: flex;
    align-items: center;
}

.incomingorderbox-top > * {
    margin-left: 3%;
    width: 50%;
    display: flex;
    align-items: center;
}

.incomingorderbox-bottom {
    width: 100%;
    height: 70%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px 3px 0 3px;  
}

.incomingorderbox-bottom > * {
    margin: 0 3%;
}

.incomingorderbox-bottom > h2 {
    letter-spacing: 2px;
}

.incomingorderbox-bottom > button {
    width: 33%;
    height: 50%;
}

.link {
    text-decoration: underline;
    color: #1D9DEB;
    cursor: pointer;
}

.transportinfo {
    position: absolute;
    z-index: 1;
    margin: auto;
    width: 50%;
    top: 5%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    background-color: #f6f8fa;
    padding: 0 2%;
}

.closepopup {
    background-image: url('../images/closemenu.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 10%;
    height: 5vh;
    cursor: pointer;
    margin-left: 85%;
    margin-top: 2%;
}

.transportinfo > h2 {
    width: 100%;
    height: 5%;
    text-align: left;
    margin: 0 2.5%;
}

.transportinfo > input {
    width: 90%;
    border: none;
    border-bottom: 1px solid #979797;
    background-color: #f6f8fa;
    padding: 2%;
    font-size: 24px;
    margin-bottom: 5%;
}

.transportcalendar {
    width: 60% !important;
    padding: 20px;
    margin: 5% 0;
    border: none;
    box-shadow: 1px 2px 4px 0 rgba(0,0,0,0.5);
}

.transportinfo > select {
    margin: 4% 80% 4% 2%;
    width: 20%;
    border: 0;
    border-radius: 0px;
    -webkit-appearance: none;
    border-bottom: 1px solid #979797 !important;
    background-color: #f6f8fa;
    font-size: 18px;
    text-align-last: right;
}

.transportinfo-add {
    width: 25%;
    height: 7vh;
    margin: 5%;
    border-radius: 24px !important;
    font-size: 1.25vw;
}

.transportinfo-cancel {
    width: 25%;
    height: 7vh;
    margin: 5%;
    border-radius: 24px !important;
    font-size: 1.25vw;
    background-color: #fff;
    border: 1px solid #1D9DEB;
}

.ordertransportinfo {
    text-align: left;
    width: 50%;
    display: flex;
    flex-flow: row wrap;
}

.ordertransportinfo > h2 {
    width: 100%;
}

.ordertransportinfo > div {
    font-weight: 900;
    width: 50%;
    margin-top: 2%;
    letter-spacing: 1px;
}

.ordertransportinfo > p {
    width: 50%;
    margin-top: 2%;
}

.wms {
    width: 95%;
    display: flex;
    flex-flow: row wrap;
}

.inv {
    width: 100%;
    border: 1px solid #979797;
    border-radius: 3px;
}

.invitemtitles {
    display: flex;
    margin: 1% auto;
    font-weight: 900;
}

.invitemtitles > div {
    width: 15%;
    text-align: left;
    margin: auto;
}

.invitemtitles > div:first-of-type, .invitem > div:first-of-type, .replenishitemtitles > div:first-of-type, .replenishitem > div:first-of-type {
    margin-left: 2%;
}

.invitem {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid #979797;
    border-radius: 3px;
    height: 6vh;
}

.invitem > div {
    width: 15%;
    text-align: left;
    margin: auto;
}

.invitem > div > select {
    width: 100%;
    height: 4vh;
}

.smallinv {
    width: 8% !important;
}

.replenish {
    width: 100%;
    border: 1px solid #979797;
    border-radius: 3px;
}

.replenishitemtitles {
    display: flex;
    margin: 1% auto;
    font-weight: 900;
}

.replenishitemtitles > * {
    width: 14%;
    margin: auto;
    text-align: left;
}

.replenishitem {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid #979797;
    border-radius: 3px;
    height: 6vh;
}

.replenishitem > * {
    width: 14%;
    text-align: left;
    margin: auto;
}

.replenishpopup {
    width: 40%;
    height: 80vh;
    position: absolute;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    right: 30%;
    top: 8%;
    z-index: 1;
    background-color: #fff;
}

.replenishpopup > h2 {
    letter-spacing: 1px;
    margin-top: 0;
    margin-bottom: 5%;
    width: 100%;
}

.replenishpopup > h4 {
    width: 30%;
    text-align: left;
    margin: 1% 5% 1% 10%;
}

.replenishpopup > span {
    width: 40%;
    text-align: left;
    margin: 1% 0 1% 5%;
}

.replenishpopup > span > input {
    border: 1px solid #979797;
    width: 25%;
    padding: 3%;
    margin-right: 3%;
}

.replenishconfirm {
    border: 1px solid #979797;
    display: flex;
    align-items: center;
    margin: 5% auto 2% auto;
    text-align: left;
}

.replenishconfirm > img {
    width: 10%;
    margin: 3%;
}

.replenishconfirm > div {
    width: 55%;
    letter-spacing: 1px;
}

.replenishconfirm > button {
    width: 20%;
    height: 25%;
    margin: auto;
}

.count {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #979797;
    border-radius: 3px;
}

.counttype {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.counttype > div {
    display: flex;
    align-items: center;
    text-align: left;
    width: 20%;
    cursor: pointer;
}

.counttype > div > input {
    margin-right: 4%;
    cursor: pointer;
    appearance: none;
    border: 0.5px solid #1D9DEB;
    border-radius: 50%;
    height: 25px;
    width: 25px;
}

.counttype > div > input:checked {
    background-color: #1D9DEB;
}

.countsettingsbutton {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.countsettingsbutton > img {
    width: 20%;
    margin-top: 5%;
}

.countsettingsbutton > h3 {
    margin: 0;
    letter-spacing: 1px;
}

.duenow, .nextcount, .past {
    width: 30%;
    margin: 2% auto;
}

.duenow > div, .nextcount > div, .past > div {
    border: 1px solid #979797;
    max-height: 63vh;
    overflow: auto;
}

.countitem {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid #979797;
    border-radius: 3px;
    height: 6vh;
}

.countitem > h3 {
    color: #1D9DEB;
    margin-left: 5%;
    cursor: pointer;
}

.countitem:first-of-type {
    border-top: none;
}

.countitem.gray {
    background-color: #EFEFEF;
}

.countsettings {
    width: 100%;
    text-align: left;
}

.cyclecount {
    width: 100%;
}

.assignjobs {
    width: 95%;
    display: flex;
    flex-flow: row wrap;
}

.assignjob {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #1D9DEB;
    border-top: 0;
}

.assignjob > div {
    width: 48%;
    text-align: left;
    margin: 1%;
}

.chooseemployee-box {
    width: 100%;
    border: 1px solid #979797;
    border-radius: 3px;
    max-height: 50vh;
    overflow: scroll;
}

.chooseemployee-box > .invitemtitles, .choosejob-box > .invitemtitles {
    height: 5vh;
    position: sticky;
    top: 0;
    background-color: #fff;
}

.chooseemployee-box > div > div, .choosejob-box > div > div {
    display: flex;
    align-items: center;
    margin-left: 2%;
}

.chooseemployee-box > div > div > input {
    margin-right: 4%;
    cursor: pointer;
    appearance: none;
    border: 0.5px solid #1D9DEB;
    border-radius: 50%;
    height: 20px;
    width: 20px;
}

.chooseemployee-box > div > div > input:checked {
    background-color: #1D9DEB;
}

.choosejob-box {
    width: 100%;
    border: 1px solid #979797;
    border-radius: 3px;
    max-height: 50vh;
    overflow: scroll;
}

.choosejob-box > div > div > form > input {
    margin-right: 4%;
    cursor: pointer;
    appearance: none;
    border: 0.5px solid #1D9DEB;
    height: 20px;
    width: 20px;
}

.choosejob-box > div > div > form > input:checked {
    background-color: #1D9DEB;
}

.jobwasassigned {
    border: 1px solid #979797;
    width: 100%;
    margin: 3% auto 0 auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.jobwasassigned > img {
    height: 75%;
}

.jobwasassigned > div {
    width: 50%;
    text-align: left;
}

.addajob {
    border: 1px solid #FD992E;
    background-color: #fff;
    color: #FD992E;
    border-radius: 16px;
    height: 5vh;
    margin: auto 3% auto 3%;
    font-size: 1.25vw;
}

.activejobs {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #1D9DEB;
    border-top: 0;
}

.activejobtitles {
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 900;
    height: 5vh;
    margin: 1% auto;
}

.activejobtitles > div:first-of-type {
    margin-left: 2%;
}

.activejobtitles > div {
    flex: 1;
    text-align: left;
}

.activejobs-box {
    width: 100%;
    border: 1px solid #979797;
    border-radius: 3px;
    max-height: 50vh;
    overflow: scroll;
}

.activejobs-box > .countitem > div {
    flex: 1;
    text-align: left;
}

.activejobs-box > .countitem > div > button {
    border-radius: 16px;
    background-color: #FD992E;
    width: 50%;
    height: 4.5vh;
    font-size: 1.4vw;
}

.roster {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #1D9DEB;
    border-top: 0;
}

.isemployeeactive {
    margin: auto;
    cursor: pointer;
    appearance: none;
    border: 0.5px solid #1D9DEB;
    height: 20px;
    width: 20px;
}
    
.isemployeeactive:checked {
    background-color: #1D9DEB;
}